import { loginSideEffect } from '@ping/authorization/side-effects';
import { Page } from '@ping/components';
import { t } from '@ping/helpers';
import { isBrowser } from '@ping/utils';

import type { NextPage } from 'next';

const Login: NextPage = () => {
  const handleLogin = () => {
    if (isBrowser()) {
      loginSideEffect();
    }
  };

  return (
    <Page>
      <div className='vh-100 d-flex justify-content-center'>
        <div className='form-access my-auto'>
          <button onClick={handleLogin}>{t('Click here for CorePass Login')}</button>
        </div>
      </div>
    </Page>
  );
};

export default Login;
