import { isTokenValidSelector, useTokenStore } from '@ping/stores/token.store';
import { isUserAdminSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { useIsUserLoggedIn } from '@ping/hooks';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

interface IPageProps {
  title?: string;
  description?: string;
  icon?: string;
  private?: boolean;
  admin?: boolean;
  children: React.ReactNode;
}

const SEO = {
  title: 'Ping',
  description: 'Cryptocurrency Exchange Dashboard',
  icon: '/favicon.svg',
};

/**
 * "If the page is private, and the user is not an admin, and the user does not have a valid token,
 * then redirect the user to the login page."
 *
 * It returns a boolean value, which is set to true if the user is allowed to
 * access the page, in case not, the user will be redirected to the login page.
 * @param {boolean} isPagePrivate - boolean - If the page is private or not
 * @param {boolean} isPageAdmin - boolean - If the page is only accessible to admins
 * @returns A boolean value that determines if the user is allowed to access the page.
 */
const useCheckPageAccess = (isPagePrivate: boolean, isPageAdmin: boolean) => {
  const [isAllowed, setIsAllowed] = useState(false);

  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const isUserLoggedIn = useIsUserLoggedIn();
  const isTokenValid = useTokenStore(isTokenValidSelector);

  const router = useRouter();
  //
  // NOTE: Since the number of condition parameters is more than 1 or 2, handling
  //       and checking all access scenarios would require a hell of a lot of
  //       if else statement inside each other. Also maintaining and debugging those
  //       parts, would be such a headache.So in order to prevent that and also
  //       reducing the order of conditions codes to O(1), we used Pattern Matching.
  const violations = useRef({
    'isPageAdmin:true|isUserAdmin:false|isPagePrivate:true|hasToken:true|isTokenValid:false': () => router.push('/'),
    'isPageAdmin:true|isUserAdmin:false|isPagePrivate:true|hasToken:false|isTokenValid:false': () => router.push('/'),
    'isPageAdmin:false|isUserAdmin:true|isPagePrivate:true|hasToken:true|isTokenValid:false': () => router.push('/'),
    'isPageAdmin:false|isUserAdmin:true|isPagePrivate:true|hasToken:false|isTokenValid:false': () => router.push('/'),
    'isPageAdmin:false|isUserAdmin:false|isPagePrivate:true|hasToken:false|isTokenValid:false': () => router.push('/'),
    'isPageAdmin:false|isUserAdmin:false|isPagePrivate:true|hasToken:false|isTokenValid:true': () => router.push('/'),
  });

  useEffect(() => {
    const condition = `isPageAdmin:${!!isPageAdmin}|isUserAdmin:${!!isUserAdmin}|isPagePrivate:${!!isPagePrivate}|hasToken:${!!isUserLoggedIn}|isTokenValid:${!!isTokenValid}`;
    violations.current[condition]?.() || setIsAllowed(true);
  }, [isPageAdmin, isUserAdmin, isPagePrivate, isUserLoggedIn, isTokenValid]);

  return isAllowed;
};

const Page = (props: IPageProps) => {
  const { children, title, description, icon, private: isPagePrivate, admin: isPageAdmin } = props;
  const isAllowed = useCheckPageAccess(isPagePrivate, isPageAdmin);

  return (
    <>
      {isAllowed && (
        <>
          <Head>
            <title>{title ?? SEO.title}</title>
            <meta name='description' content={description ?? SEO.description} />
            <link rel='icon' href={icon ?? SEO.icon} />
          </Head>

          {children}
        </>
      )}
    </>
  );
};

export default Page;
